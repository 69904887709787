<template>
  <div id="pagina-sempermissao-page">
    <div id="pagina-sempermissao-container">
      <div id="pagina-sempermissao-container-titulo-oops">
        <div class="display-4 text-danger" style="text-align: center">
          <i class="fas fa-ban"></i>
        </div>
        <div id="pagina-sempermissao-titulo-oops" class="text-danger">Oops!</div>
        <div id="pagina-sempermissao-titulo-msg" class="px-2">O usuário administrador não pode acessar a rotina!</div>
        <div class="col-12 mt-3 pt-3" style="text-align: center" v-if="exibeBotaoSair">
          <router-link id="lmxta-sempermissao-btn-sair" class="btn btn-secondary" :to="{ name: 'Logout' }">
            <span style="position: absolute; left: 10px">
              <i class="fas fa-power-off"></i>
            </span>
            Sair
          </router-link>
        </div>
      </div>
    </div>

    <div id="pagina-sempermissao-page-container">
      <div id="pagina-sempermissao-container-nome-app">
        <div id="pagina-sempermissao-nome-app-linxmicrovix">Linx Microvix</div>
        <div id="pagina-sempermissao-nome-app-estoque">Suprimentos</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { AUTENTICACAO_ESTOQUE } from '@/store/modules/autenticacao/autenticacao';

  export default {
    name: 'NavegacaoErroAdminPermissao',

    computed: {
      ...mapState({
        exibeBotaoSair: (state) => {
          return state.autenticacao.metodoAutenticacao.tipo == AUTENTICACAO_ESTOQUE;
        },
      }),
    },
  };
</script>

<style scoped>
  #pagina-sempermissao-page {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #pagina-sempermissao-container {
    flex-grow: 9;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  #pagina-sempermissao-container-titulo-oops {
    flex-grow: 1;
    text-align: right;
  }

  #pagina-sempermissao-titulo-oops {
    font-size: 4rem !important;
    font-weight: bolder;
    font-family: Dosis, sans-serif;
    color: #5b2e90;
    text-align: center;
    margin-top: -30px !important;
  }

  #pagina-sempermissao-container-titulo-msg {
    flex-grow: 1;
    text-align: left;
    text-align: center;
  }

  #pagina-sempermissao-titulo-msg {
    font-size: 1.5rem !important;
    font-family: Dosis, sans-serif;
    color: #5b2e90;
    text-align: center;
  }

  #pagina-sempermissao-nome-app-linxmicrovix {
    text-align: center;
    font-family: Dosis, sans-serif;
    font-size: 2rem;
    color: #ffb200;
  }

  #pagina-sempermissao-nome-app-estoque {
    text-align: center;
    font-family: Dosis, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    color: #5b2e90;
  }

  .btn {
    line-height: 1.5 !important;
  }

  #btn-sair {
    width: 200px;
    position: relative;
  }

  @media only screen and (max-width: 600px) {
    #btn-sair {
      display: block !important;
      width: 100% !important;
    }
  }
</style>
